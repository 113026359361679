import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import RegisterForCustomerService from "components/ui/extended/RegisterForCustomerService";
import PlainHeader from "components/ui/base/PlainHeader";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";
import TestimonialWithImageIrene from "components/ui/extended/TestimonialWithImageIrene";
import Customers from "components/ui/extended/Customers";
import FeatureGrid from "components/ui/base/FeatureGrid";
import NumberSteps from "components/ui/base/NumberSteps";
import ConversationInboxTabs from "components/ui/extended/ConversationInboxTabs";
import { IGatsbyImageData } from "gatsby-plugin-image";
import CannedResponseTemplates from "components/ui/extended/CannedResponseTemplates";
import { CannedResponseTemplate } from "app-types/template";

interface ConversationInboxPageProps {
  data: {
    allCannedResponsesTemplates: {
      nodes: CannedResponseTemplate[];
    };
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: ConversationInboxPageProps) => {
  const { t } = useTranslation();
  const cannedResponseTemplates = data.allCannedResponsesTemplates.nodes;

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.conversation-inbox.seo.title")}
        description={t("pages.conversation-inbox.seo.description")}
        image={{
          relativePath: "meta/conversation-inboxes-team-chat.jpg",
          alt: "Conversation Inbox Team Chat",
        }}
      />

      <PlainHeader
        title={t("pages.conversation-inbox.title")}
        description={t("pages.conversation-inbox.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={1000}
        descriptionMaxWidth={500}
        image={{
          relativePath: "pages/conversation-inbox/header.jpg",
          alt: "Customerly",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Conversations Inbox",
            path: "/conversation-inbox/",
          },
        ]}
      />

      <NumberSteps
        title={t("pages.conversation-inbox.steps.title")}
        elements={Array.from(Array(4).keys()).map((i) => {
          return {
            title: t(`pages.conversation-inbox.steps.${i + 1}.title`),
            description: t(
              `pages.conversation-inbox.steps.${i + 1}.description`
            ),
          };
        })}
      />

      <ConversationInboxTabs />

      <CannedResponseTemplates
        showMore
        templates={cannedResponseTemplates.slice(0, 5)}
      />

      <FeatureGrid
        title={t("pages.conversation-inbox.toolbar.title")}
        mediumImages
        centered={false}
        sections={[
          {
            title: t(`pages.conversation-inbox.toolbar.1.title`),
            description: t(`pages.conversation-inbox.toolbar.1.description`),
            image: {
              relativePath: `pages/conversation-inbox/chat-replies-toolbar-reply-box-1.jpg`,
              alt: t(`pages.conversation-inbox.toolbar.1..title`),
            },
          },
          {
            title: t(`pages.conversation-inbox.toolbar.2.title`),
            description: t(`pages.conversation-inbox.toolbar.2.description`),
            image: {
              relativePath: `pages/conversation-inbox/chat-replies-toolbar-reply-box-2.jpg`,
              alt: t(`pages.conversation-inbox.toolbar.2.title`),
            },
          },
          {
            title: t(`pages.conversation-inbox.toolbar.3.title`),
            description: t(`pages.conversation-inbox.toolbar.3.description`),
            image: {
              relativePath: `pages/conversation-inbox/chat-replies-toolbar-reply-box-3.jpg`,
              alt: t(`pages.conversation-inbox.toolbar.3.title`),
            },
          },
          {
            title: t(`pages.conversation-inbox.toolbar.4.title`),
            description: t(`pages.conversation-inbox.toolbar.4.description`),
            image: {
              relativePath: `pages/conversation-inbox/chat-replies-toolbar-reply-box-4.jpg`,
              alt: t(`pages.conversation-inbox.toolbar.4.title`),
            },
          },
          {
            title: t(`pages.conversation-inbox.toolbar.5.title`),
            description: t(`pages.conversation-inbox.toolbar.5.description`),
            image: {
              relativePath: `pages/conversation-inbox/chat-replies-toolbar-reply-box-5.jpg`,
              alt: t(`pages.conversation-inbox.toolbar.5.title`),
            },
          },
          {
            title: t(`pages.conversation-inbox.toolbar.6.title`),
            description: t(`pages.conversation-inbox.toolbar.6.description`),
            image: {
              relativePath: `pages/conversation-inbox/chat-replies-toolbar-reply-box-6.jpg`,
              alt: t(`pages.conversation-inbox.toolbar.6.title`),
            },
          },
        ]}
      />

      <TestimonialWithImageIrene />

      <RegisterForCustomerService />

      <Customers />
    </GlobalWrapper>
  );
};

export const conversationInboxQuery = graphql`
  query {
    allCannedResponsesTemplates {
      nodes {
        canned_response_template_id
        emoji
        description
        background
        canned_response {
          is_shared
          shortcut
          text
          title
        }
        author {
          account_id
          bio
          id
          public_name
          username
          website
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: { eq: "meta/conversation-inboxes-team-chat.jpg" }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
